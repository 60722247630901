/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment FooterBlock_Fragment on ComposeComponentWlFooterBlock {\n    about3PLearning\n    aboutUs\n    blakeELearningLogo {\n      url\n    }\n    collaborateWithUs\n    contactUs\n    dataBreachResponsePlan\n    facebook\n    instagram\n    linkedIn\n    pLearningLogo {\n      url\n    }\n    pinterest\n    privacyPolicy\n    refundPolicy\n    responsibleDisclosureProgram\n    termsOfUse\n    tikTok\n    twitter\n    youtube\n  }\n": types.FooterBlock_FragmentFragmentDoc,
    "\n  query WritingLegendsFooterBlock($id: String!, $locale: String) {\n    composeComponentWlFooterBlock(id: $id, locale: $locale) {\n      ...FooterBlock_Fragment\n    }\n  }\n": types.WritingLegendsFooterBlockDocument,
    "\n  fragment ProductOwnersBlock_Fragment on ComposeComponentImageAndAdjacentText {\n    sys {\n      id\n    }\n    __typename\n    image {\n      url\n      title\n    }\n    imageAlignment\n    text {\n      json\n    }\n  }\n": types.ProductOwnersBlock_FragmentFragmentDoc,
    "\n  fragment StudentOutcomesBlock_Fragment on WritingLegendsMarketingSiteIndexPageStudentOutcomesBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n": types.StudentOutcomesBlock_FragmentFragmentDoc,
    "\n  fragment ProductSummaryBlock_Fragment on WritingLegendsMarketingSiteIndexPageSummaryBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n": types.ProductSummaryBlock_FragmentFragmentDoc,
    "\n  fragment TeacherSupportStatementBlock_Fragment on WritingLegendsMarketingSiteIndexPageTeacherSupportBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n": types.TeacherSupportStatementBlock_FragmentFragmentDoc,
    "\n  fragment VideoBlock_Fragment on WritingLegendsMarketingSiteIndexPageVideoBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n": types.VideoBlock_FragmentFragmentDoc,
    "\n    fragment ComposeComponentVideoAndAdjacentText_Fragment on ComposeComponentVideoAndAdjacentText {\n      __typename\n      sys {\n        id\n      }\n      videoAlignment\n      video {\n        url\n        title\n        description\n      }\n      description {\n        json\n        links {\n          assets {\n            block {\n              sys {\n                id\n              }\n              ... on Asset {\n                title\n                description\n                url\n                width\n                height\n              }\n            }\n          }\n        }\n      }\n    }\n  ": types.ComposeComponentVideoAndAdjacentText_FragmentFragmentDoc,
    "\n  fragment ComposeComponentText_Fragment on ComposeComponentText {\n    __typename\n    sys {\n      id\n    }\n    text {\n      json\n    }\n    title\n  }\n": types.ComposeComponentText_FragmentFragmentDoc,
    "\n    fragment ComposeComponentImageAndAdjacentText_Fragment on ComposeComponentImageAndAdjacentText {\n      __typename\n      sys {\n        id\n      }\n      imageAlignment\n      image {\n        url\n        title\n        description\n      }\n      text {\n        json\n      }\n    }\n  ": types.ComposeComponentImageAndAdjacentText_FragmentFragmentDoc,
    "\n  fragment ComposeComponentTextList_Fragment on ComposeComponentTextList {\n    __typename\n    sys {\n      id\n    }\n    textItemsCollection(limit: 20) {\n      __typename\n      items {\n        sys {\n          id\n        }\n        __typename\n        ...ComposeComponentText_Fragment\n      }\n    }\n  }\n": types.ComposeComponentTextList_FragmentFragmentDoc,
    "\n  fragment RichTextBlockEntry_Fragment on Entry {\n    __typename\n    sys {\n      id\n    }\n    ... on ComposeComponentText {\n      ...ComposeComponentText_Fragment\n    }\n    ... on ComposeComponentImageAndAdjacentText {\n      ...ComposeComponentImageAndAdjacentText_Fragment\n    }\n    ... on ComposeComponentVideoAndAdjacentText {\n      ...ComposeComponentVideoAndAdjacentText_Fragment\n    }\n    ... on ComposeComponentTextList {\n      ...ComposeComponentTextList_Fragment\n    }\n  }\n": types.RichTextBlockEntry_FragmentFragmentDoc,
    "\n  query WritingLegendsMarketingSiteAboutUsPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteAboutUsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      aboutUsText {\n        json\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n    }\n  }\n": types.WritingLegendsMarketingSiteAboutUsPageDocument,
    "\n  query WritingLegendsMarketingSiteContactUsPage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSiteContactUsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      contactUsText {\n        json\n      }\n      formLink\n    }\n  }\n": types.WritingLegendsMarketingSiteContactUsPageDocument,
    "\n  fragment Faqs_Fragment on WritingLegendsMarketingSiteFaQsPageFrequentlyAskedQuestions {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n            __typename\n          }\n          ... on ComposeComponentTextList {\n            ...ComposeComponentTextList_Fragment\n          }\n        }\n      }\n    }\n  }\n": types.Faqs_FragmentFragmentDoc,
    "\n  query WritingLegendsMarketingSiteFaQsPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteFaQsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      frequentlyAskedQuestions {\n        json\n        ...Faqs_Fragment\n      }\n    }\n  }\n": types.WritingLegendsMarketingSiteFaQsPageDocument,
    "\n  query WritingLegendsMarketingSiteIndexPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteIndexPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      email\n      phoneNumber\n      summaryBlock {\n        ...ProductSummaryBlock_Fragment\n      }\n      videoBlock {\n        ...VideoBlock_Fragment\n      }\n      studentOutcomesBlock {\n        ...StudentOutcomesBlock_Fragment\n      }\n      productOwnersBlock {\n        ...ProductOwnersBlock_Fragment\n      }\n      teacherSupportBlock {\n        ...TeacherSupportStatementBlock_Fragment\n      }\n    }\n  }\n": types.WritingLegendsMarketingSiteIndexPageDocument,
    "\n  query WritingLegendsMarketingSitePrivacyPolicyPage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSitePrivacyPolicyPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      privacyPolicyBody {\n        json\n      }\n    }\n  }\n": types.WritingLegendsMarketingSitePrivacyPolicyPageDocument,
    "\n  fragment SoftwareRequirements_Fragment on WritingLegendsMarketingSiteTechReqPageSoftwareRequirements {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n            __typename\n          }\n          ... on ComposeComponentTextList {\n            ...ComposeComponentTextList_Fragment\n          }\n        }\n      }\n    }\n  }\n": types.SoftwareRequirements_FragmentFragmentDoc,
    "\n    query writingLegendsMarketingSiteTechReqPage(\n      $id: String!\n      $locale: String\n    ) {\n      writingLegendsMarketingSiteTechReqPage(id: $id, locale: $locale) {\n        sys {\n          id\n        }\n        seoPageTitle\n        seoPageDescription\n        seoKeywords\n        softwareRequirements {\n          json\n          ...SoftwareRequirements_Fragment\n        }\n      }\n    }\n  ": types.WritingLegendsMarketingSiteTechReqPageDocument,
    "\n  query writingLegendsMarketingSiteTermsOfUsePage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSiteTermsOfUsePage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      termsOfUse {\n        json\n      }\n    }\n  }\n": types.WritingLegendsMarketingSiteTermsOfUsePageDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FooterBlock_Fragment on ComposeComponentWlFooterBlock {\n    about3PLearning\n    aboutUs\n    blakeELearningLogo {\n      url\n    }\n    collaborateWithUs\n    contactUs\n    dataBreachResponsePlan\n    facebook\n    instagram\n    linkedIn\n    pLearningLogo {\n      url\n    }\n    pinterest\n    privacyPolicy\n    refundPolicy\n    responsibleDisclosureProgram\n    termsOfUse\n    tikTok\n    twitter\n    youtube\n  }\n"): (typeof documents)["\n  fragment FooterBlock_Fragment on ComposeComponentWlFooterBlock {\n    about3PLearning\n    aboutUs\n    blakeELearningLogo {\n      url\n    }\n    collaborateWithUs\n    contactUs\n    dataBreachResponsePlan\n    facebook\n    instagram\n    linkedIn\n    pLearningLogo {\n      url\n    }\n    pinterest\n    privacyPolicy\n    refundPolicy\n    responsibleDisclosureProgram\n    termsOfUse\n    tikTok\n    twitter\n    youtube\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WritingLegendsFooterBlock($id: String!, $locale: String) {\n    composeComponentWlFooterBlock(id: $id, locale: $locale) {\n      ...FooterBlock_Fragment\n    }\n  }\n"): (typeof documents)["\n  query WritingLegendsFooterBlock($id: String!, $locale: String) {\n    composeComponentWlFooterBlock(id: $id, locale: $locale) {\n      ...FooterBlock_Fragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductOwnersBlock_Fragment on ComposeComponentImageAndAdjacentText {\n    sys {\n      id\n    }\n    __typename\n    image {\n      url\n      title\n    }\n    imageAlignment\n    text {\n      json\n    }\n  }\n"): (typeof documents)["\n  fragment ProductOwnersBlock_Fragment on ComposeComponentImageAndAdjacentText {\n    sys {\n      id\n    }\n    __typename\n    image {\n      url\n      title\n    }\n    imageAlignment\n    text {\n      json\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StudentOutcomesBlock_Fragment on WritingLegendsMarketingSiteIndexPageStudentOutcomesBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment StudentOutcomesBlock_Fragment on WritingLegendsMarketingSiteIndexPageStudentOutcomesBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductSummaryBlock_Fragment on WritingLegendsMarketingSiteIndexPageSummaryBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ProductSummaryBlock_Fragment on WritingLegendsMarketingSiteIndexPageSummaryBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TeacherSupportStatementBlock_Fragment on WritingLegendsMarketingSiteIndexPageTeacherSupportBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TeacherSupportStatementBlock_Fragment on WritingLegendsMarketingSiteIndexPageTeacherSupportBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment VideoBlock_Fragment on WritingLegendsMarketingSiteIndexPageVideoBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment VideoBlock_Fragment on WritingLegendsMarketingSiteIndexPageVideoBlock {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n          }\n          ... on ComposeComponentVideoAndAdjacentText {\n            ...ComposeComponentVideoAndAdjacentText_Fragment\n          }\n          ... on ComposeComponentImageAndAdjacentText {\n            ...ComposeComponentImageAndAdjacentText_Fragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ComposeComponentVideoAndAdjacentText_Fragment on ComposeComponentVideoAndAdjacentText {\n      __typename\n      sys {\n        id\n      }\n      videoAlignment\n      video {\n        url\n        title\n        description\n      }\n      description {\n        json\n        links {\n          assets {\n            block {\n              sys {\n                id\n              }\n              ... on Asset {\n                title\n                description\n                url\n                width\n                height\n              }\n            }\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    fragment ComposeComponentVideoAndAdjacentText_Fragment on ComposeComponentVideoAndAdjacentText {\n      __typename\n      sys {\n        id\n      }\n      videoAlignment\n      video {\n        url\n        title\n        description\n      }\n      description {\n        json\n        links {\n          assets {\n            block {\n              sys {\n                id\n              }\n              ... on Asset {\n                title\n                description\n                url\n                width\n                height\n              }\n            }\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ComposeComponentText_Fragment on ComposeComponentText {\n    __typename\n    sys {\n      id\n    }\n    text {\n      json\n    }\n    title\n  }\n"): (typeof documents)["\n  fragment ComposeComponentText_Fragment on ComposeComponentText {\n    __typename\n    sys {\n      id\n    }\n    text {\n      json\n    }\n    title\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ComposeComponentImageAndAdjacentText_Fragment on ComposeComponentImageAndAdjacentText {\n      __typename\n      sys {\n        id\n      }\n      imageAlignment\n      image {\n        url\n        title\n        description\n      }\n      text {\n        json\n      }\n    }\n  "): (typeof documents)["\n    fragment ComposeComponentImageAndAdjacentText_Fragment on ComposeComponentImageAndAdjacentText {\n      __typename\n      sys {\n        id\n      }\n      imageAlignment\n      image {\n        url\n        title\n        description\n      }\n      text {\n        json\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ComposeComponentTextList_Fragment on ComposeComponentTextList {\n    __typename\n    sys {\n      id\n    }\n    textItemsCollection(limit: 20) {\n      __typename\n      items {\n        sys {\n          id\n        }\n        __typename\n        ...ComposeComponentText_Fragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ComposeComponentTextList_Fragment on ComposeComponentTextList {\n    __typename\n    sys {\n      id\n    }\n    textItemsCollection(limit: 20) {\n      __typename\n      items {\n        sys {\n          id\n        }\n        __typename\n        ...ComposeComponentText_Fragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment RichTextBlockEntry_Fragment on Entry {\n    __typename\n    sys {\n      id\n    }\n    ... on ComposeComponentText {\n      ...ComposeComponentText_Fragment\n    }\n    ... on ComposeComponentImageAndAdjacentText {\n      ...ComposeComponentImageAndAdjacentText_Fragment\n    }\n    ... on ComposeComponentVideoAndAdjacentText {\n      ...ComposeComponentVideoAndAdjacentText_Fragment\n    }\n    ... on ComposeComponentTextList {\n      ...ComposeComponentTextList_Fragment\n    }\n  }\n"): (typeof documents)["\n  fragment RichTextBlockEntry_Fragment on Entry {\n    __typename\n    sys {\n      id\n    }\n    ... on ComposeComponentText {\n      ...ComposeComponentText_Fragment\n    }\n    ... on ComposeComponentImageAndAdjacentText {\n      ...ComposeComponentImageAndAdjacentText_Fragment\n    }\n    ... on ComposeComponentVideoAndAdjacentText {\n      ...ComposeComponentVideoAndAdjacentText_Fragment\n    }\n    ... on ComposeComponentTextList {\n      ...ComposeComponentTextList_Fragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WritingLegendsMarketingSiteAboutUsPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteAboutUsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      aboutUsText {\n        json\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n    }\n  }\n"): (typeof documents)["\n  query WritingLegendsMarketingSiteAboutUsPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteAboutUsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      aboutUsText {\n        json\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WritingLegendsMarketingSiteContactUsPage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSiteContactUsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      contactUsText {\n        json\n      }\n      formLink\n    }\n  }\n"): (typeof documents)["\n  query WritingLegendsMarketingSiteContactUsPage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSiteContactUsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      contactUsText {\n        json\n      }\n      formLink\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Faqs_Fragment on WritingLegendsMarketingSiteFaQsPageFrequentlyAskedQuestions {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n            __typename\n          }\n          ... on ComposeComponentTextList {\n            ...ComposeComponentTextList_Fragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Faqs_Fragment on WritingLegendsMarketingSiteFaQsPageFrequentlyAskedQuestions {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n            __typename\n          }\n          ... on ComposeComponentTextList {\n            ...ComposeComponentTextList_Fragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WritingLegendsMarketingSiteFaQsPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteFaQsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      frequentlyAskedQuestions {\n        json\n        ...Faqs_Fragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query WritingLegendsMarketingSiteFaQsPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteFaQsPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      frequentlyAskedQuestions {\n        json\n        ...Faqs_Fragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WritingLegendsMarketingSiteIndexPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteIndexPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      email\n      phoneNumber\n      summaryBlock {\n        ...ProductSummaryBlock_Fragment\n      }\n      videoBlock {\n        ...VideoBlock_Fragment\n      }\n      studentOutcomesBlock {\n        ...StudentOutcomesBlock_Fragment\n      }\n      productOwnersBlock {\n        ...ProductOwnersBlock_Fragment\n      }\n      teacherSupportBlock {\n        ...TeacherSupportStatementBlock_Fragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query WritingLegendsMarketingSiteIndexPage($id: String!, $locale: String) {\n    writingLegendsMarketingSiteIndexPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      email\n      phoneNumber\n      summaryBlock {\n        ...ProductSummaryBlock_Fragment\n      }\n      videoBlock {\n        ...VideoBlock_Fragment\n      }\n      studentOutcomesBlock {\n        ...StudentOutcomesBlock_Fragment\n      }\n      productOwnersBlock {\n        ...ProductOwnersBlock_Fragment\n      }\n      teacherSupportBlock {\n        ...TeacherSupportStatementBlock_Fragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WritingLegendsMarketingSitePrivacyPolicyPage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSitePrivacyPolicyPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      privacyPolicyBody {\n        json\n      }\n    }\n  }\n"): (typeof documents)["\n  query WritingLegendsMarketingSitePrivacyPolicyPage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSitePrivacyPolicyPage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      privacyPolicyBody {\n        json\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SoftwareRequirements_Fragment on WritingLegendsMarketingSiteTechReqPageSoftwareRequirements {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n            __typename\n          }\n          ... on ComposeComponentTextList {\n            ...ComposeComponentTextList_Fragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SoftwareRequirements_Fragment on WritingLegendsMarketingSiteTechReqPageSoftwareRequirements {\n    __typename\n    json\n    links {\n      entries {\n        block {\n          sys {\n            id\n            __typename\n          }\n          ... on ComposeComponentTextList {\n            ...ComposeComponentTextList_Fragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query writingLegendsMarketingSiteTechReqPage(\n      $id: String!\n      $locale: String\n    ) {\n      writingLegendsMarketingSiteTechReqPage(id: $id, locale: $locale) {\n        sys {\n          id\n        }\n        seoPageTitle\n        seoPageDescription\n        seoKeywords\n        softwareRequirements {\n          json\n          ...SoftwareRequirements_Fragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    query writingLegendsMarketingSiteTechReqPage(\n      $id: String!\n      $locale: String\n    ) {\n      writingLegendsMarketingSiteTechReqPage(id: $id, locale: $locale) {\n        sys {\n          id\n        }\n        seoPageTitle\n        seoPageDescription\n        seoKeywords\n        softwareRequirements {\n          json\n          ...SoftwareRequirements_Fragment\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query writingLegendsMarketingSiteTermsOfUsePage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSiteTermsOfUsePage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      termsOfUse {\n        json\n      }\n    }\n  }\n"): (typeof documents)["\n  query writingLegendsMarketingSiteTermsOfUsePage(\n    $id: String!\n    $locale: String\n  ) {\n    writingLegendsMarketingSiteTermsOfUsePage(id: $id, locale: $locale) {\n      sys {\n        id\n      }\n      seoPageTitle\n      seoPageDescription\n      seoKeywords\n      termsOfUse {\n        json\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;